"use client";

import { Paths } from "../Paths";
import { Text } from "@egocentric-systems/ts-apis/types/v1/text_pb";
import { useCart } from "~/hooks/useCart";
import {
  CartItem,
  COOKIE_PREFERENCES_KEY,
  DEFAULT_COOKIE_EXPIRE,
  DEFAULT_COOKIE_MAX_AGE,
  EGOSYS_LNG_COOKIE,
  GeneratePathParams,
  SHIPMENT_METHOD_KEY,
} from "../definitions";
import { reservation } from "./reservation";
import { StripeElementLocale } from "@stripe/stripe-js";
import { PlainMessage, proto3 } from "@bufbuild/protobuf";
import { ShopLanguage } from "@egocentric-systems/ts-apis/shop_config/types/v1/common_pb";
import { parse } from "accept-language-parser";
import Cookies from "universal-cookie";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { ShipmentMethod } from "@egocentric-systems/ts-apis/shop_config/types/v1/shipment_pb";
import { toast } from "~/components/ui/use-toast";
import { gtmChangeLanguage } from "./gtm";

dayjs.extend(timezone);
dayjs.extend(utc);

export const changeLanguage = (code: string) => {
  const cookies = new Cookies();

  if (typeof navigator === "undefined") return;
  const browserLng = parse(navigator.language)[0]?.code;
  const currentLng = getLocale();

  if (code === browserLng) {
    cookies.remove(EGOSYS_LNG_COOKIE, {
      path: "/",
      sameSite: "strict",
    });
  } else {
    cookies.set(EGOSYS_LNG_COOKIE, code, {
      path: "/",
      sameSite: "strict",
      expires: dayjs.utc().add(1, "year").toDate(),
      maxAge: dayjs.utc().add(1, "year").diff(dayjs(), "seconds"),
    });
  }

  gtmChangeLanguage(currentLng, code);
};

export function generatePath(path: Paths, params?: GeneratePathParams): string {
  if (typeof window === "undefined") return path;

  const url = new URL(window.location.toString());
  url.searchParams.delete("redirect");

  let newPath: string = path;

  if (params !== undefined) {
    newPath = path.replace(/:(\w+)/g, (match, key) => {
      return params[key as keyof typeof params] || match;
    });
  }

  if (path !== Paths.ROOT && newPath.endsWith("/")) {
    newPath = newPath.slice(0, -1);
  }

  url.pathname = newPath;

  if (params?.redirect) {
    url.searchParams.set("redirect", encodeURIComponent(params.redirect));
  }

  return url.pathname + url.search;
}

export function localeToStripeElementLocale(
  locale: string,
): StripeElementLocale {
  switch (locale) {
    case "en":
    case "de":
      return locale;

    default: {
      return "de";
    }
  }
}

export async function makeRequests(
  storedItems: CartItem[],
  temporaryItems: CartItem[],
): Promise<{ items: CartItem[] }> {
  const requests =
    temporaryItems.length > 0
      ? temporaryItems.map((item) => {
          const storedItem = storedItems.find(
            ({ section, price, event, series }) =>
              section.id === item.section.id &&
              price.id === item.price.id &&
              event.id === item.event.id &&
              series.id === item.series.id,
          );

          const amount = storedItem
            ? item.quantity - storedItem.quantity
            : item.quantity;

          return { ...item, amount };
        })
      : storedItems.map(({ section, price, event, series, quantity }) => ({
          section,
          price,
          event,
          series,
          amount: -quantity,
        }));

  const filteredRequests = requests.filter(({ amount }) => amount !== 0);

  if (filteredRequests.length === 0) {
    return { items: storedItems };
  }

  for (const { section, price, event, series, amount } of filteredRequests) {
    const token = useCart.getState().reservationToken ?? "";

    if (amount < 0) {
      const value = Math.abs(amount);

      await reservation.cancelSeats({
        reservationToken: token,
        eventId: event.id,
        sectionId: section.id,
        seating: { case: "seatCount", value },
      });

      const index = storedItems.findIndex(
        (item) =>
          item.section.id === section.id &&
          item.price.id === price.id &&
          item.event.id === event.id &&
          item.series.id === series.id,
      );

      if (index !== -1) {
        storedItems[index].quantity -= value;
      }
    } else {
      const response = await reservation.reserveSeats({
        reservationToken: token,
        eventId: event.id,
        sectionId: section.id,
        seating: { case: "seatCount", value: amount },
      });

      if (response.reservationToken) {
        useCart.getState().setReservationToken(response.reservationToken);
      }

      if (response.seating.case === "seatCount") {
        const { value } = response.seating;

        if (value !== amount) {
          toast({
            description: `Die gewünschte Anzahl an Tickets konnte nicht reserviert werden.`,
            variant: "destructive",
          });
        }

        const index = storedItems.findIndex(
          (item) =>
            item.section.id === section.id &&
            item.price.id === price.id &&
            item.event.id === event.id &&
            item.series.id === series.id,
        );

        if (index !== -1) {
          storedItems[index].quantity += value;
        } else {
          storedItems.push({ section, price, event, series, quantity: value });
        }
      }
    }
  }

  const updatedItems = storedItems.filter(({ quantity }) => quantity > 0);

  if (updatedItems.length === 0) {
    useCart.getState().setReservationToken(null);
  }

  return { items: updatedItems };
}

export const translateText = (
  data: PlainMessage<Text> | undefined | null,
): string => {
  if (!data) {
    return "";
  }

  const lng = getLocale();

  if (data.translations[lng]) {
    return data.translations[lng];
  }

  return data.translations[data.defaultLanguage];
};

export function getLocale(): string {
  if (typeof navigator === "undefined") return "";

  const browserLng = parse(navigator.language)[0]?.code;
  const cookies = new Cookies();
  const cookieLng = cookies.get(EGOSYS_LNG_COOKIE)?.value;

  const supportedLocales = proto3
    .getEnumType(ShopLanguage)
    .values.map((value) => value.localName.toLowerCase());

  const check = (lng: string) => {
    if (supportedLocales.includes(lng)) return lng;
    return "en";
  };

  if (cookieLng) return check(cookieLng);
  if (browserLng) return check(browserLng);
  return "en";
}

export const getHost = (): string => {
  const url = new URL(window.location.toString());
  return url.hostname;
};

export function isNecessaryAccepted(): boolean {
  const cookies = new Cookies();
  const settings = cookies.get(COOKIE_PREFERENCES_KEY);
  return Boolean(settings && settings.necessary);
}

export function persistShipmentMethod(method: ShipmentMethod) {
  const cookies = new Cookies();
  if (method === ShipmentMethod.UNSPECIFIED) {
    cookies.remove(SHIPMENT_METHOD_KEY, {
      path: "/",
      sameSite: "strict",
    });

    return;
  }

  cookies.set(SHIPMENT_METHOD_KEY, method.toString(), {
    path: "/",
    maxAge: DEFAULT_COOKIE_MAX_AGE,
    expires: DEFAULT_COOKIE_EXPIRE,
  });
}
