import { ShipmentMethod } from "@egocentric-systems/ts-apis/shop_config/types/v1/shipment_pb";
import { CartItem } from "../definitions";
import { z } from "zod";
import { CheckoutSchema } from "./schemas/checkout";

let db: IDBDatabase;
let version = 1;
const DB_KEY = "cart";
const PROVIDER_KEY = "egosys";

function checkCompatibility(): boolean {
  if (typeof window === "undefined") {
    return false;
  }
  if (!("indexedDB" in window)) {
    return false;
  }
  return true;
}

function getData<T>(key: string): Promise<T | null> {
  if (!checkCompatibility()) {
    if (typeof window === "undefined") {
      return Promise.resolve(null);
    }

    const data = window.localStorage.getItem(key);

    if (!data) {
      return Promise.resolve(null);
    }

    try {
      return Promise.resolve(JSON.parse(data));
    } catch {
      return Promise.resolve(data as T);
    }
  }

  return new Promise(async (resolve) => {
    if (!db) {
      try {
        await initDb();
      } catch {
        resolve(null);
      }
    }

    const transaction = db.transaction(DB_KEY, "readonly");
    const objectStore = transaction.objectStore(DB_KEY);

    const request = objectStore.get(key);

    request.onsuccess = () => {
      resolve(request.result ? request.result[key] : null);
    };

    request.onerror = () => {
      resolve(null);
    };
  });
}

function setData(key: string, data: any): Promise<void> {
  if (!checkCompatibility()) {
    if (typeof window === "undefined") {
      return Promise.resolve();
    }

    window.localStorage.setItem(key, JSON.stringify(data));
    return Promise.resolve();
  }

  return new Promise(async (resolve, reject) => {
    if (!db) {
      try {
        await initDb();
      } catch {
        reject("DB konnte nicht initialisiert werden");
      }
    }

    const transaction = db.transaction(DB_KEY, "readwrite");
    const objectStore = transaction.objectStore(DB_KEY);

    objectStore.put({
      id: key,
      [key]: data,
    });

    transaction.oncomplete = () => {
      resolve();
    };

    transaction.onerror = (event) => {
      reject(event);
    };

    transaction.onabort = (event) => {
      reject(event);
    };
  });
}

export function saveReservationToken(token: string | null): Promise<void> {
  return setData("reservationToken", token);
}

export function getReservationToken(): Promise<string | null> {
  return getData<string | null>("reservationToken");
}

export function getCartItems(): Promise<CartItem[] | null> {
  return getData<CartItem[]>("cartItems");
}

export function saveShipmentMethod(
  shipmentMethod: ShipmentMethod,
): Promise<void> {
  return setData("shipmentMethod", shipmentMethod);
}

export function getShipmentMethod(): Promise<ShipmentMethod | null> {
  return getData<ShipmentMethod>("shipmentMethod");
}

export function saveCartItems(items: CartItem[]): Promise<void> {
  return setData("cartItems", items);
}

export function saveCustomer(
  data: z.infer<CheckoutSchema> | null,
): Promise<void> {
  return setData("customer", data);
}

export function getCustomer(): Promise<z.infer<CheckoutSchema> | null> {
  return getData<z.infer<CheckoutSchema> | null>("customer");
}

export function setAuthToken(token: string): Promise<void> {
  return setData("authToken", token);
}

export function getAuthToken(): Promise<string | null> {
  return getData<string>("authToken");
}

export function deleteAuthToken(): Promise<void> {
  return setData("authToken", null);
}

function initDb(): Promise<void> {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(PROVIDER_KEY, version);

    request.onerror = (event) => {
      console.error("IndexedDB konnte nicht geöffnet werden: ", event);
      reject(event);
    };

    request.onsuccess = (event: any) => {
      db = event.target.result;
      resolve();
    };

    request.onupgradeneeded = (event: any) => {
      db = event.target.result;

      if (!db.objectStoreNames.contains(DB_KEY)) {
        db.createObjectStore(DB_KEY, {
          autoIncrement: true,
          keyPath: "id",
        });
      }
    };
  });
}
