"use client";

import { create } from "zustand";
import {
  CartItem,
  ReserveCancelRegister,
  ReserveCancelRegisterSet,
} from "~/lib/definitions";
import { makeRequests, persistShipmentMethod } from "~/lib/client/utils";
import { ShipmentMethod } from "@egocentric-systems/ts-apis/shop_config/types/v1/shipment_pb";
import { calculateCartExpireDelta, logIfDev } from "~/lib/utils";
import {
  gtmCartExpired,
  gtmCancelSeat,
  gtmReserveSeat,
  gtmSetSeat,
} from "~/lib/client/gtm";
import {
  getCartItems,
  getCustomer,
  getReservationToken,
  getShipmentMethod,
  saveCartItems,
  saveReservationToken,
} from "~/lib/client/db";
import { CheckoutSchema } from "~/lib/client/schemas/checkout";
import { z } from "zod";
import { reservation } from "~/lib/client/reservation";
import { toast } from "~/components/ui/use-toast";

type UseItems = {
  currency: string;
  customer: z.infer<CheckoutSchema> | null;
  reservationTokenFetched: boolean;
  items: CartItem[];
  removeItem: (item: CartItem) => Promise<null>;
  setItems: (items: CartItem[]) => void;
  setUiItems: (items: CartItem[]) => void;
  setTimer: (timer: string | null) => void;
  setReservationToken: (token: string | null) => void;
  loading: boolean;
  uiItems: CartItem[];
  reserveSeat: (register: ReserveCancelRegister) => void;
  cancelSeat: (register: ReserveCancelRegister) => void;
  setSeats: (data: ReserveCancelRegisterSet) => void;
  manipulating: boolean;
  reservationToken: string | null;
  cartTimer: string | null;
  shipmentMethod: ShipmentMethod | null;
  setShipmentMethod: (method: ShipmentMethod) => void;
};

export function cartCheckInterval(): void {
  const { items, uiItems, manipulating, loading, reservationToken } =
    useCart.getState();

  logIfDev(
    "Checking cart",
    items,
    uiItems,
    manipulating,
    loading,
    reservationToken,
  );

  if (manipulating || loading) return;

  if (reservationToken && (items.length === 0 || uiItems.length === 0)) {
    useCart.setState({
      currency: "EUR",
      items: [],
      uiItems: [],
      cartTimer: null,
      reservationToken: null,
    });

    saveReservationToken(null).catch(logIfDev);
    saveCartItems([]).catch(logIfDev);

    return;
  }

  if (!reservationToken && (items.length > 0 || uiItems.length > 0)) {
    useCart.setState({
      currency: "EUR",
      items: [],
      uiItems: [],
      cartTimer: null,
    });
    return;
  }

  if (reservationToken) {
    const timestamp = calculateCartExpireDelta(reservationToken);
    useCart.setState({ cartTimer: timestamp });

    if (!timestamp) {
      gtmCartExpired(items);

      logIfDev("Cart expired", items, uiItems, reservationToken);

      saveReservationToken(null).catch(logIfDev);
      saveCartItems([]).catch(logIfDev);

      useCart.setState({
        reservationToken: null,
        currency: "EUR",
        items: [],
        uiItems: [],
      });
    }
  } else {
    useCart.setState({ cartTimer: null });
  }
}

const cache = [];
let reserveTimeout: NodeJS.Timeout | null = null;
let cacheMergeTimeout: NodeJS.Timeout | null = null;

function calculateCurrency(items: CartItem[]): string {
  return items.reduce((acc, item) => {
    if (item.price.value?.currency) {
      return item.price.value.currency;
    }
    return acc;
  }, "EUR");
}

getShipmentMethod().then((method) => {
  if (typeof window === "undefined") return;
  useCart.setState({ shipmentMethod: method });
});

getReservationToken()
  .then((token) => {
    if (typeof window === "undefined") return;
    useCart.setState({
      reservationToken: token,
      reservationTokenFetched: true,
    });
  })
  .catch(() => {
    if (typeof window === "undefined") return;
    useCart.setState({ reservationTokenFetched: true });
  });

getCartItems().then((items) => {
  if (typeof window === "undefined") return;
  if (!items) {
    useCart.setState({ items: [], uiItems: [] });
    return;
  }

  useCart.setState({ items: items, uiItems: items });
});

getCustomer().then((customer) => {
  if (typeof window === "undefined") return;
  useCart.setState({ customer: customer });
});

export const useCart = create<UseItems>((set, get) => {
  const setItems = (items: CartItem[]): void => {
    set({ items: items, currency: calculateCurrency(items) });
    saveCartItems(items).catch(logIfDev);
  };

  const setUiItems = (items: CartItem[]): void => {
    set({ currency: calculateCurrency(items), uiItems: items });
  };

  const setTimer = (timer: string | null): void => {
    set({ cartTimer: timer });
  };

  const setReservationToken = (token: string | null): void => {
    set({ reservationToken: token, reservationTokenFetched: true });
    saveReservationToken(token).catch(logIfDev);
  };

  const setShipmentMethod = (method: ShipmentMethod): void => {
    set({ shipmentMethod: method });
    persistShipmentMethod(method);
  };

  const reserveSeat = (register: ReserveCancelRegister): void => {
    clearTimeout(reserveTimeout as unknown as number);
    set({ manipulating: true });
    const items = structuredClone(get().uiItems);

    const index = items.findIndex(
      ({ section, price, event, series }) =>
        section.id === register.section.id &&
        price.id === register.price.id &&
        event.id === register.event.id &&
        series.id === register.series.id,
    );

    if (index === -1) {
      items.push({
        ...structuredClone(register),
        quantity: 1,
      });
    } else {
      items[index] = {
        ...items[index],
        quantity: items[index].quantity + 1,
      };
    }

    gtmReserveSeat(register, items);
    set({ uiItems: items });

    doMagic(register);
  };

  const cancelSeat = (register: ReserveCancelRegister): void => {
    clearTimeout(reserveTimeout as unknown as number);
    set({ manipulating: true });
    const items = structuredClone(get().uiItems);

    const index = items.findIndex(
      ({ section, price, event, series }) =>
        section.id === register.section.id &&
        price.id === register.price.id &&
        event.id === register.event.id &&
        series.id === register.series.id,
    );

    if (index === -1) {
      return;
    }

    items[index] = {
      ...items[index],
      quantity: items[index].quantity - 1,
    };

    gtmCancelSeat(register, items);
    set({ uiItems: items });

    doMagic(register);
  };

  const setSeats = (register: ReserveCancelRegisterSet): void => {
    clearTimeout(reserveTimeout as unknown as number);
    set({ manipulating: true });
    const items = structuredClone(get().uiItems);

    const index = items.findIndex(
      ({ section, price, event, series }) =>
        section.id === register.section.id &&
        price.id === register.price.id &&
        event.id === register.event.id &&
        series.id === register.series.id,
    );

    if (index === -1) {
      items.push(structuredClone(register));
    } else {
      items[index] = {
        ...items[index],
        quantity: register.quantity,
      };
    }

    gtmSetSeat(register, items);
    set({ uiItems: items });

    doMagic(register);
  };

  async function removeItem(item: CartItem): Promise<null> {
    const token = get().reservationToken;

    if (!token) {
      return Promise.resolve(null);
    }

    return reservation
      .cancelSeats({
        eventId: item.event.id,
        sectionId: item.section.id,
        reservationToken: token,
        seating: {
          case: "seatCount",
          value: item.quantity,
        },
      })
      .then(() => {
        const items = structuredClone(get().items);
        const uiItems = structuredClone(get().uiItems);

        const index = items.findIndex(
          ({ section, price, event, series }) =>
            section.id === item.section.id &&
            price.id === item.price.id &&
            event.id === item.event.id &&
            series.id === item.series.id,
        );

        if (index === -1) {
          return null;
        }

        items.splice(index, 1);

        const uiIndex = uiItems.findIndex(
          ({ section, price, event, series }) =>
            section.id === item.section.id &&
            price.id === item.price.id &&
            event.id === item.event.id &&
            series.id === item.series.id,
        );

        if (uiIndex === -1) {
          return null;
        }

        uiItems.splice(uiIndex, 1);

        setItems(items);
        setUiItems(uiItems);

        if (items.length === 0) {
          setReservationToken(null);
        }

        return null;
      });
  }

  return {
    setItems,
    currency: "EUR",
    removeItem,
    setUiItems,
    setTimer,
    setReservationToken,
    customer: null,
    setShipmentMethod,
    shipmentMethod: null,
    items: [],
    uiItems: [],
    loading: false,
    manipulating: false,
    reservationToken: null,
    cartTimer: null,
    reserveSeat,
    cancelSeat,
    setSeats,
    reservationTokenFetched: false,
  };
});

function doMagic(register: ReserveCancelRegister) {
  const { loading } = useCart.getState();

  if (loading) {
    if (cacheMergeTimeout) {
      clearTimeout(cacheMergeTimeout);
    }

    cache.push(register);
    cacheMergeTimeout = setTimeout(() => {
      logIfDev("now the cache should be merged");
    }, 1000);

    return;
  }

  reserveTimeout = setTimeout(async () => {
    useCart.setState({ loading: true });
    const { items: currentItems, uiItems: currentUiItems } = useCart.getState();

    try {
      const { items: newItems } = await makeRequests(
        structuredClone(currentItems),
        structuredClone(currentUiItems),
      );

      useCart.setState({
        loading: false,
        manipulating: false,
        items: structuredClone(newItems),
        uiItems: structuredClone(newItems),
      });

      saveCartItems(newItems).catch(logIfDev);
    } catch {
      useCart.setState({ loading: false, manipulating: false, items: currentItems, uiItems: currentItems });

      toast({
        description: "Die gewünschte Anzahl an Tickets konnte nicht reserviert werden.",
        variant: "destructive",
      });
    }
  }, 1000);
}
